.order-form-field-function {
    margin-left: 10px;
}
.order-form-field-function-faux-anchor {
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
    color: #1601EE;
}
.order-form-admin-notes-add {
    display: flex;
    align-items: center;
}