.preview-button {
    margin-right: 20px;
    color: #BFC7D6;
    cursor: pointer;
}

.preview-active {
    color: #1E1D5C;
}

.preview-device-mobile {
    width: 375px;
}

.preview-device-tablet {
    width: 768px;
}

.preview-device-desktop {
    width: 1980px;
}

.preview-container {
    position: relative;
}

.preview-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;
}

.preview-error {
    text-align: center;
    vertical-align: middle;
    padding: auto;
    height: 768px;
}

.mobile {
    width: 375px;
}

.tablet {
    width: 768px;
}

.desktop {
    width: 1440px;
}
