.reorder-cell-row {
    height: 57px;
}

.reorder-table-header td {
    font-weight: 700;
    color: #082954;
}

.reorder-cell-price {
    width: 125px;
}