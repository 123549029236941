.block-delete-button {
    margin-left: 15px !important;
}
.position-arrows {
    display: flex;
    justify-content: flex-end;
}
.position-arrows svg {
    cursor: pointer;
    width: 30px;
    height: 30px;
}
.deleted-block {
    display: none;
}