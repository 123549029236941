.menu-item-row-status-icons {
    width: 112px;
}
.menu-item-row-status-icons span {
    margin-left: 24px;
}
.menu-item-row-title {
    margin-left: 20px;
}
.menu-item-row-new-button-wrapper, .menu-item-row-new-button-icon {
    color: #019EDA;
}
.menu-item-row-new-button-wrapper {
    cursor: pointer;
}
.menu-item-row-new-button-wrapper:hover {
    text-decoration: underline;
}
.icon-padding-left {
    padding-left: 39px;
}
.menu-item-sync-icon {
    font-size: 1rem !important;
}
.menu-item-back-arrow {
    font-size: 1rem !important;
    margin-right: 7px;
}