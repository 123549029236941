.remove-rplus-card-button {
    display: flex;
    align-items: center;
}

.remove-rplus-card-card-number {
    margin-right: 12px !important;
}

.remove-rplus-card-icon {
    font-size: 18px !important;
    color: #7895C1;
}