.product-category-table {
    border-collapse: separate !important;
    border: 1px solid #E4E8F7;
    border-radius: 10px;
}

.product-category-row-caret {
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    color: #7895C1;
}

.product-category-row-status-icons {
    display: flex !important;
    justify-content: flex-end;
}

.product-category-row-status-icon, .product-category-row-reorder-icon {
    font-size: 21px;
}

.product-category-row-status-icon-is_active-false {
    color: #A9AFDB;
}

.product-category-row-status-icon-is_active-true {
    color: #019EDA;
}

.product-category-row-status-icon-featured, .product-category-row-reorder-icon {
    color: #7895C1;
    margin-right: 22px;
}

.product-category-row-reorder-icon:hover {
    color: #31486b;
    cursor: pointer;
}

