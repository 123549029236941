.image-section-root {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    margin-top: 30px;
}

.image-section-root .MuiTextField-root {
    margin: 0;
}

.image-section-image-field {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    width: 152px;
    height: 152px;
    margin-right: 15px;
}

.image-section-alt-field,
.image-section-title-tag-field {
    grid-column: 2 / 5;
    grid-row: 1 / 2;
}

.image-section-og-description-field {
    grid-column: 2 / 5;
    grid-row: 2 / 3;
}

.image-section-meta-title-field {
    grid-column: 2 / 5;
    grid-row: 3 / 4;
}

.image-section-meta-description-field {
    grid-column: 2 / 5;
    grid-row: 4 / 5;
}

.og_image_url_wrapper div {
    margin-top: 0px;
}

.ck.ck-balloon-panel {
    min-height: 15px;
    background: #456399;
    border: 1px solid #456399;
}
.ck.ck-link-form input {
    color: #2c3441;
}
.inovua-react-pagination-toolbar .inovua-react-toolkit-numeric-input,
.inovua-react-pagination-toolbar .inovua-react-toolkit-combo-box {
    width: 80px !important;
    border: 1px solid #294475 !important;
    border-radius: 4px !important;
}
.inovua-react-toolkit-date-input,
.InovuaReactDataGrid__column-header__filter--select {
    border-color: #7895c1 !important;
    border-radius: 4px !important;
}
.inovua-react-toolkit-date-input__calendar-icon svg {
    fill: #294475;
}
.overwrite-arrow {
    display: flex;
    align-items: center;
    font-size: 18px;
    padding-top: 0px !important;
}

.custom-error-helper {
    color: #d8474e !important;
    white-space: nowrap;
}

.helper-text-nowrap {
    white-space: nowrap;
}

.InovuaReactDataGrid .inovua-react-virtual-list,
.InovuaReactDataGrid__body {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.datetime-local-filled input[type="datetime-local"]::-webkit-calendar-picker-indicator {
    visibility: hidden;
    width: 0px;
    -webkit-appearance: none;
}

.ck::-webkit-scrollbar-track {
    border-radius: 10px;
}

.modal-grey-background {
    background-color: "#F7F9FF";
}
.InovuaReactDataGrid__row:hover {
    cursor: pointer;
}
.multiselect-delete-icon {
    display: flex;
    align-items: center;
}
.multiselect-chip {
    margin-right: 5px;
}
.detailed-display-right-button-component {
    display: flex;
    align-items: center;
    line-height: 32px;
}

.detailed-display-right-button-separator {
    border-left: 1px solid #294475;
    height: 36px;
    margin-left: 20px;
}
.draggable-icon {
    font-size: 15px !important;
    color: #7895c1;
}

.draggable-ellipsis-icon {
    width: 5px !important;
}

.draggable-table-icon-cell {
    width: 60px;
}

.icon-wrapper {
    color: #7895c1;
}

.icon-wrapper .fa-link {
    font-size: 18px;
}

.icon-wrapper:hover {
    cursor: pointer;
    color: #7895c1;
}
.MuiTableRow-root {
    border-top: 1px solid #ebeef9 !important;
    border-left: 1px solid #ebeef9 !important;
    border-right: 1px solid #ebeef9 !important;
}
.MuiTableCell-root {
    padding: 8px 16px 8px 16px !important;
}
.InovuaReactDataGrid__column-header__content {
    line-height: 1.6 !important;
}
.InovuaReactDataGrid__column-header {
    flex: auto !important;
}