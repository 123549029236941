.group-wrapper {
    display: flex;
    align-items: center;
}

.group-name {
    color: #2C3441;
    font-weight: bold;
    font-size: 16px;
}

.group-select {
    font-size: 16px;
    margin-left: 30px;
    color: #019EDA;
}

.group-select:hover {
    cursor: pointer;
}