.order-form-show-on-map {
    margin-left: 10px;
}
.order-form-admin-notes-add {
    display: flex;
    align-items: center;
}
.image-radio-wrapper {
    padding: 5px;
    border: 1px solid #E4E8F7;
    border-radius: 5px;
    margin: 3px 5px;
}
.image-radio-wrapper:hover {
    border-color: #294475;
    cursor: pointer;
}
.design-selected {
    padding: 3px;
    border: 3px solid #294475;
}